.scroll {
    max-height: 350px;
    overflow-y: auto;
}

.cardStyle {
    border-color: #F4F4FE !important;
    background-color: #F4F4FE !important;
}

.card-footer {
    background-color: #f9f9f9 !important;
    border-top-color: #fff !important;
}

.card-title{
    font-size: xx-large !important;
}

.card-text{
    text-align: justify !important;
    text-justify: inter-word !important;
}